/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
    padding: 60px 0;
    overflow: hidden;
    position: relative;
    .section-title {
        text-align: center;
        padding-bottom: 30px;
        position: relative;
        h2 {
            font-size: 32px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 20px;
            padding-bottom: 20px;
            position: relative;
            &::after {
                content: "";
                position: absolute;
                display: block;
                width: 50px;
                height: 3px;
                background: var(--oksijen-turuncu);
                bottom: 0;
                left: calc(50% - 25px);
            }
        }
        p {
            margin-bottom: 0;
        }
    }
    &.section-bg {
        padding: 120px 0;
        color: rgba(255, 255, 255, 1);
        &:before {
            content: "";
            background: var(--oksijen-mavi);
            position: absolute;
            bottom: 60px;
            top: 60px;
            left: 0;
            right: 0;
            transform: skewY(-3deg);
        }
    }
}
